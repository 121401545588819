import forEach from "for-each"
import { Handler, DOMHandler } from "./handler"
import { safe } from "../../common/tools"
import { consoleLogLevel } from "../events/typedef"
import { events, logLevel } from "../events/typedef"
import { truncate } from "../../ext/json-truncate"
import { Utils } from "../utils/utils"
import { Options } from "./../Options"
import { IHooks } from "./../hooks"
import { IEventsStream } from "./../events/stream"
import { IEventsListener } from "../../common/eventListener"

type IUtils = typeof Utils
type IOptions = typeof Options

interface IStackTrace {
    url: string | null
    func: string | null
    args: string[] | [string] | []
    line: number | null
    column: number | null
}

interface IConsole {
    Register: () => void
    Unregister: () => void
    addEvent: (type: string, obj: any) => void
    Console: (level: string, args: any) => void
    ErrorHandlder(e: ErrorEvent): void
}

class Console extends DOMHandler implements IConsole {
    private utils: IUtils
    private eventsStream: IEventsStream
    private options: IOptions
    private hooks: IHooks
    private globalEvents: IEventsListener
    private maxLogsPerPage: number
    private maxErrorsPerPage: number
    private maxObjDepth: number
    private maxObjKeys: number
    private maxStringLength: number
    private watchedEvents: object
    private buff: []
    private enabled: boolean
    private debugForce: boolean
    private inited: boolean
    private errorFunc: (e: ErrorEvent) => void
    private exceptionFunc: (e: PromiseRejectionEvent) => void

    public constructor({
        utils,
        eventsStream,
        options,
        hooks,
        globalEvents,
        enabled,
    }: {
        utils: IUtils
        options: IOptions
        eventsStream: IEventsStream
        hooks: IHooks
        globalEvents: IEventsListener
        enabled: boolean
    }) {
        super()
        this.utils = utils
        this.options = options
        this.eventsStream = eventsStream
        this.hooks = hooks
        this.globalEvents = globalEvents

        this.errorFunc = (e) => safe(this.ErrorHandlder.bind(this), e)
        this.exceptionFunc = (e) => safe(this.ExceptionHandler.bind(this), e)

        this.maxLogsPerPage = 256
        this.maxErrorsPerPage = 256
        this.maxObjDepth = 3
        this.maxObjKeys = 32
        this.maxStringLength = 128

        this.watchedEvents = {}
        this.buff = []
        this.enabled = enabled || false
        this.inited = false
    }

    Register() {
        window.addEventListener("error", this.errorFunc, true)
        window.addEventListener("unhandledrejection", this.exceptionFunc, true)

        this.logCounter = 0
        this.errCounter = 0

        this.logReached = false
        this.errReached = false

        const _this = this
        if (this.hooks.CanBind(console, "log")) {
            this.watchedEvents = {
                warn: this.hooks.Create(console, "warn", {
                    before: function () {
                        _this.Console("warn", arguments)
                    },
                }),
                error: this.hooks.Create(console, "error", {
                    before: function () {
                        _this.Console("error", arguments)
                    },
                }),
                log: this.hooks.Create(console, "log", {
                    before: function () {
                        _this.Console("log", arguments)
                    },
                }),
                info: this.hooks.Create(console, "info", {
                    before: function () {
                        _this.Console("info", arguments)
                    },
                }),
            }
        }

        // init handler after receiving settings from API
        this.globalEvents.once("api.session.inited", ({ settings }) => {
            this.inited = true
            this.enabled = settings.consoleLogs
            this.debugForce = settings.debugForce

            if (!this.enabled) {
                this.buff = []
                return
            }
            if (this.buff.length) {
                forEach(this.buff, (v) => {
                    this.eventsStream.Add(v.type, v.obj, v.time)
                })
                this.buff = []
            }
        })
    }

    addEvent(type: string, obj: any) {
        if (this.enabled) {
            this.eventsStream.Add(type, obj)
        }
        if (!this.inited) {
            this.buff.push({ type, obj, time: this.utils.Time.Duration() })
        }
    }

    Unregister() {
        window.removeEventListener("error", this.errorFunc, true)
        window.removeEventListener("unhandledrejection", this.exceptionFunc, true)

        this.watchedEvents.warn && this.hooks.Unbind(this.watchedEvents.warn)
        this.watchedEvents.error && this.hooks.Unbind(this.watchedEvents.error)
        this.watchedEvents.log && this.hooks.Unbind(this.watchedEvents.log)
        this.watchedEvents.info && this.hooks.Unbind(this.watchedEvents.info)
    }

    ErrorHandlder(e: ErrorEvent): void {
        if (this.isMax("err", this.maxErrorsPerPage)) return
        const msg = e.message,
            file = e.filename,
            lineno = e.lineno
        const stackTrace = getStackTrace(e.error)
        const json_data = file || lineno || stackTrace ? [file, lineno, stackTrace] : null
        if (msg || file || lineno || stackTrace) {
            this.addEvent(events.ERROR, { value: msg, json_data: json_data })
        }
    }

    ExceptionHandler(e: PromiseRejectionEvent): void {
        if (this.isMax("err", this.maxErrorsPerPage)) return

        const reason = Object.assign({}, e.reason, {
            message: e.reason?.message || undefined,
            stack: e.reason?.stack || undefined
        })

        this.addEvent(events.ERROR, { value: "Uncaught (in promise)", json_data: [reason] })
    }

    ConsoleError(logs: []) {
        if (this.isMax("err", this.maxErrorsPerPage)) return
        const obj = { json_data: logs }
        obj.omitLog = !!this.debugForce && true
        this.addEvent(events.ERROR, obj)
    }

    isMax(type: string, limit: number): boolean {
        if (this[`${type}Counter`] >= limit) {
            if (!this[`${type}Reached`]) {
                this[`${type}Reached`] = true
                this.addEvent(events.LOG, {
                    json_data: [logLevel["internal"], "tracking.console.maxLogs", type],
                })
            }
            return true
        } else {
            this[`${type}Counter`]++
            return false
        }
    }

    parseObject(obj: {}): {} {
        let result: {} = {}
        result = truncate(obj, {
            maxString: this.maxStringLength,
            maxDepth: this.maxObjDepth,
            maxKeys: this.maxObjKeys,
            replace: "_max_",
        })
        return result
    }

    Console(level: string, args: any) {
        if (!this.debugForce) {
            if (this.isMax("log", this.maxLogsPerPage)) return
        }

        let logs: [] = []
        if (args) {
            // exclude internal logs
            if (!this.debugForce && window.__ls_debug && level === "log") {
                if (typeof args[0] === "string" && args[0].substr(0, 4) === "[LS]") {
                    return
                }
            }

            if (this.debugForce && !Array.isArray(args)) {
                args = [args]
            }

            forEach(Array.prototype.slice.call(args, 0, this.maxObjKeys), (a: {}) => {
                logs.push(this.parseObject(a))
            })

            if (level === consoleLogLevel.error) {
                this.ConsoleError(logs)
            } else {
                const obj = {
                    json_data: [logLevel[level] || -1, logs],
                }
                obj.omitLog = !!this.debugForce && true

                this.addEvent(events.LOG, obj)
            }
        }
    }
}

const getStackTrace = function (e: any): IStackTrace | null | undefined {
    if (e && e.stack) {
        const r = /^\s*at (.*?) ?\(((?:file|https?|blob|chrome-extension|native|eval|<anonymous>).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i,
            o = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|resource|\[native).*?)(?::(\d+))?(?::(\d+))?\s*$/i,
            u = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i
        const items = e.stack.split("\n")
        let res = []
        let item: IStackTrace

        for (let i = 0, temp, len = items.length; i < len; ++i) {
            if ((temp = r.exec(items[i]))) {
                var d = temp[2] && -1 !== temp[2].indexOf("native")
                item = {
                    url: d ? null : temp[2],
                    func: temp[1] || "?",
                    args: d ? [temp[2]] : [],
                    line: temp[3] ? +temp[3] : null,
                    column: temp[4] ? +temp[4] : null,
                }
            } else if ((temp = u.exec(items[i]))) {
                item = {
                    url: temp[2],
                    func: temp[1] || "?",
                    args: [],
                    line: +temp[3],
                    column: temp[4] ? +temp[4] : null,
                }
            } else {
                if (!(temp = o.exec(items[i]))) continue
                item = {
                    url: temp[3],
                    func: temp[1] || "?",
                    args: temp[2] ? temp[2].split(",") : [],
                    line: temp[4] ? +temp[4] : null,
                    column: temp[5] ? +temp[5] : null,
                }
            }
            if (!item.func && item.line) item.func = "?"
            res.push(item)
        }
        return res.length
            ? (res[0].column || void 0 === e.columnNumber || (res[0].column = e.columnNumber + 1),
              {
                  name: e.name,
                  message: e.message,
                  url: window.location.href,
                  stack: res,
              })
            : null
    }
}

export { Console, IConsole }
