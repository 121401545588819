import { debug } from "./../../common/tools"

const ctxLog = (...args) => {
    debug("XHRTransport", ...args)
}

type Inactivity = number[]

interface IBundlePayload {
    events: []
    activeTime: number
    inactivity: Inactivity[]
    tabID: number
}
class XHRTransport {
    constructor({ options, globalEvents, visitor, pageView }) {
        this.visitor = visitor
        this.options = options
        this.globalEvents = globalEvents
        this.pageView = pageView
    }

    Init() {}

    getAPI() {
        const optApiHost = this.options.Get("apiHost")

        if (optApiHost) {
            return optApiHost + "/visitors"
        }

        return process.env.API_URL + "/visitors"
    }

    sendFetch(method, url, data, onSuccess, onError) {
        return fetch(url, {
            method: method,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => {
                return res.json().then((data) => {
                    return new Promise((resolve, reject) => {
                        switch (res.status) {
                            case 200:
                                return onSuccess(data)
                            default:
                                return onError(data)
                        }
                    })
                })
            })
            .catch((err) => {
                ctxLog("Error:", err)
                return new Promise((resolve, reject) => {
                    reject(err)
                })
            })
    }

    sendXHR(method, url, data, onSuccess, onError) {
        const xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                try {
                    xhr && xhr.status === 200 ? onSuccess(xhr.responseText) : onError && onError({ statusCode: xhr.status, response: xhr.responseText })
                } catch (e) {
                    ctxLog(e)
                }
            }
        }
        xhr.open(method, url, true)
        // xhr.setRequestHeader("Content-Type", "application/json")
        xhr.send(JSON.stringify(data))
    }

    handleError(error) {
        if (!error) return
        switch (error.type) {
            case "turn_off":
                this.globalEvents.call("api.response.turn_off")
                break
            case "session_expired":
                this.globalEvents.call("api.session.expired")
                break
            case "malformed_data":
                this.globalEvents.call("api.session.malformed_data")
                break
            default:
                ctxLog(`Unsupported error: ${error}`)
        }
    }

    send(method, path, data, params, onSuccess, onError, useBeaconAPI = false) {
        params.account_id = this.options.AccountID()

        if (this.options.WebsiteID() != -1) {
            params.website_id = this.options.WebsiteID()
        } else {
            console.warn("[LiveSession] Your tracking script is outdated. Please update it.")
        }

        const qs = Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        let url = this.getAPI() + path + "?" + qs

        if (useBeaconAPI && navigator.sendBeacon) {
            navigator.sendBeacon(url, JSON.stringify(data))
            ctxLog(`Send ${path} (beacon):`, data)
            return
        }
        ctxLog(`Send ${path}:`, data)

        return this.sendXHR(
            method,
            url,
            data,
            (responseData) => {
                const json = JSON.parse(responseData)
                if (json && json.error) {
                    const { error } = json
                    this.handleError(error)
                    return onError(error)
                }
                return onSuccess(json)
            },
            ({ response }) => {
                try {
                    const json = JSON.parse(response)
                    if (json && json.error && json.error.type == "session_expired") {
                        this.globalEvents.call("api.session.expired")
                    }
                    onError(json.error)
                } catch (e) {
                    ctxLog(e)
                }
            },
        )
    }

    SendBundle(payload: IBundlePayload, onSuccess, onError, params, useBeaconAPI, error) {
        return this.send(
            "POST",
            "/bundle",
            {
                page_view_id: this.pageView.ID(),
                page_creation_timestamp: this.pageView.CreationTimestamp(),
                visitor_side_storage: this.visitor.JSON(),
                events: payload.events,
                inactivity: payload.inactivity,
                activeTime: payload.activeTime,
                tab_id: payload.tabID,
                error: error,
            },
            params,
            onSuccess,
            onError,
            useBeaconAPI,
            error,
        )
    }

    PageViewInit(onSuccess, onError) {
        return this.send("GET", "/init", null, {}, onSuccess, onError)
    }

    SendPageView(data, onSuccess, onError) {
        return this.send("POST", "/page_view", data, {}, onSuccess, onError)
    }

    SendIntegrationProps(data, onSuccess, onError) {
        return this.send("POST", "/props", data, {}, onSuccess, onError)
    }
}

export { XHRTransport }
